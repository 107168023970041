export default {
    baseURL: 'https://test.leslunettesoho.com',
    login: '/api/v1/auth/login',
    allAgency: '/api/v1/agency/get-agency',
    swich: '/api/v1/auth/switch-agency',
    updatePass: '/api/v1/auth/update-password',
    getProduct: '/api/v1/product/get-products',
    getProductParginate: '/api/v1/product/get-paginated-product',
    createClient: '/api/v1/client/sell/create-client',
    getClient: '/api/v1/client/sell/get-clients',
    liteClient: '/api/v1/client/lite-client',
    filterClient: '/api/v1/client/filter-client',
    updateClient: '/api/v1/client/sell/update-client',
    createEstimate: '/api/v1/estimate/sell/create-estimate',
    addArticle: '/api/v1/estimate/sell/add-articles',
    createPrice: '/api/v1/price/sell/create-product-price',
    allDevis: '/api/v1/estimate/sell/get-estimate',
    allCompany: '/api/v1/insurance/get-insurance',
    recharge: '/api/v1/wallet/sell/fund-wallet',
    addInssurance: '/api/v1/estimate/sell/add-insurance-support',
    parseCommande: '/api/v1/orders/sell/create-order',
    getCommande: '/api/v1/orders/sell/get-orders',
    mesure: '/api/v1/estimate/sell/add-estimate-sub-data',
    checkCoupon: '/api/v1/coupon/check',
    updateEstimate: '/api/v1/estimate/sell/update-estimate',
    allMarque: '/api/v1/brand/get-brands',
    allGamme: '/api/v1/range/get-ranges',
    updateDevis: '/api/v1/estimate/sell/update-estimate',
    getCommerciaux: '/api/v1/staff/get-staffs',
    addDoctor: '/api/v1/estimate/sell/add-commercial-or-doctor',
    upload: '/api/v1/utility/upload',
    updateArticle: '/api/v1/estimate/sell/update-multiple-articles',
    deleteArticle: '/api/v1/estimate/sell/delete-estimate-article',
    encaissement: '/api/v1/orders/sell/pay-order',
    deleteEstimate: '/api/v1/estimate/sell/delete-estimate',
    updateState: '/api/v1/orders/sell/update-order',
    createEvent: '/api/v1/client/create-event',
    getEvent: '/api/v1/client/get-events',
    clientStat: '/api/v1/client/get-client-stats',
    agenceStat: '/api/v1/agency/sell/get-stats',
    generatePdf: '/api/v1/estimate/generate-estimate-pdf',
    orderPaginate: '/api/v1/orders/sell/get-paginated-order',
    devisPaginate: '/api/v1/estimate/sell/get-paginated-estimate',
    filtreClient: '/api/v1/client/filter-client',
    payMultiple: '/api/v1/orders/sell/pay-multiple-order',
    agenceStats: '/api/v1/wallet/get-agency-wallet-stats',
    agenceHistorique: '/api/v1/wallet/get-wallets-transactions',
    agenceHistoriquePaginate: '/api/v1/wallet/get-paginated-wallets-transactions',
    userWallet: '/api/v1/wallet/get-user-wallet',
    transfereWallet: '/api/v1/wallet/agency-transfer',
    allCheque: '/api/v1/wallet/get-not-transfer-bank-check',
    allMomo: '/api/v1/wallet/get-not-transfer-mobile-money',
    historique: '/api/v1/wallet/get-wallet-transfer',
    allBank: '/api/v1/wallet/get-all-bank',
    allDataDeposit: '/api/v1/wallet/get-ready-for-deposit',
    remise: '/api/v1/wallet/agency-deposit',
    imprimeFiche: '/api/v1/wallet/get-wallet-form',
    liteproduct: '/api/v1/product/get-lite-products',
    deleteInsurance: '/api/v1/estimate/sell/delete-support'
}
